<template>
  <div v-if="miniview" class="min-h-full flex flex-col items-center">
    <h4 class="text-3xl sm:text-4.5xl font-bold mb-5">Welcome!</h4>
    <span class="text-lg font-roboto text-grayDark text-center" :class="miniview.explanation_media_url ? 'mb-5' : 'mb-16'">
      {{ miniview.user_full_name }} has invited you to fill out these MINIVIEWs™ Questions.
    </span>

    <div v-if="miniview.explanation_media_url" class="w-full mb-13">
      <VideoPreview v-if="miniview.explanation_media_type === 'video_recordings'" :media-src="miniview.explanation_media_url" />
      <AudioPreview v-else-if="miniview.explanation_media_type === 'audio_recordings'" :media-src="miniview.explanation_media_url" />
    </div>


    <div class="mb-16 w-full">
      <p class="text-black mb-2.5">Your name</p>
      <input v-model="form.name" class="form-input rounded-full focus:border-pink-500 focus:shadow-none py-3 px-4 w-full disabled:bg-guestio-gray-100 disabled:text-gray-500 mb-4">
    </div>

    <div class="flex">
      <button
        class="bg-indigo-gradiant text-white px-28 h-16 rounded-full font-bold text-xl font-roboto uppercase"
        :class="{'opacity-50 cursor-not-allowed': form.name == ''}"
        :disabled="form.name == ''"
        @click="nextStep"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VideoPreview from '@/components/shared/VideoPreview';
import AudioPreview from '@/components/shared/AudioPreview';

export default {
  name: "MiniviewAnswerName",
  components: { VideoPreview, AudioPreview },

  computed: {
    ...mapGetters({
      miniview: 'miniviews/current',
    }),

    ...mapState({
      form: state => state.miniviews.answerForm,
    }),
  },

  methods: {
    nextStep() {
      this.$router.push({ name: 'MiniviewAnswerQuestions' });
    },
  },
};
</script>
